import React, { useRef, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import { useTranslation } from 'react-i18next';
import { language } from './Settings'

function translate_label(stitekInfo, language) {
  console.log(stitekInfo);
  if (language === 'en') {
    let newStitek = stitekInfo.replace('VRATKA', 'RETURN')
    newStitek = newStitek.replace('REKLAMACE', 'CLAIM')
    return newStitek
  }
  if (language === 'fr') {
    let newStitek = stitekInfo.replace('VRATKA', 'RETOUR')
    newStitek = newStitek.replace('REKLAMACE', 'RECLAMATION')
    return newStitek
  }
  return stitekInfo
}


async function trigger_emails() {
    let url = 'https://vratky.bola.cz/vratky-backend/mail.php'
    let result = false

    await fetch(url)
    .then(res => res.text())
    .then((out) => {
      result = out
    })
    .catch(err => {
      console.log(err)
    });

    return result
  }


export function Finished(props) {

  useEffect(() => {    
    trigger_emails()
  }, [])

  const componentRef = useRef();
  const { t } = useTranslation();
  
  return (
          
      <div className="row">

        <div className="col-12">

          {props.typVraceni === 'vratka' && <h2>{t('Vratka byla vytvořena.')}</h2>}
          {props.typVraceni === 'reklamace' && <h2>{t('Reklamace byla vytvořena.')}</h2>}

          <div className="row mb-3">
            <div className="col-11">

            <div className="row mb-3">
              <div className="col-11">
                <div className="alert alert-primary" role="alert">
                  <strong>{t('Prosím zašlete či doručte balík na adresu:')}</strong>
                  <br />
                  BOLA spol s.r.o.<br />
                  Severní 276<br />
                  252 25 Jinočany<br />
                  Czech Republic<br />
                  <br />
                  <strong>{t('Vepište na štítek/balík:')}</strong> <br />
                  <br />
                  <strong>{translate_label(props.stitekInfo, language)}</strong>
                  <br /><br />
                  <strong>{t('Produkt ponechte v originální krabičce a tu zabalte do přepravní krabice (aby se nepoškodila krabice produktu).')}</strong> <br />
                  <br /><br />
                  {language === 'en' && props.typVraceni === 'vratka' &&
                    <img alt='' src="https://vratky.bola.cz/return_en.jpg" style={{maxWidth: '400px', width: '100%'}} />
                  }
                  {language === 'en' && props.typVraceni === 'reklamace' &&
                    <img alt='' src="https://vratky.bola.cz/claim_en.jpg" style={{maxWidth: '400px', width: '100%'}} />
                  }
                  {(language === 'cz' || language === 'sk') && props.typVraceni === 'vratka' &&
                    <img alt='' src="https://vratky.bola.cz/return_cz.jpg" style={{maxWidth: '400px', width: '100%'}} />
                  }
                  {language === 'cz' && props.typVraceni === 'reklamace' &&
                    <img alt='' src="https://vratky.bola.cz/claim_cz.jpg" style={{maxWidth: '400px', width: '100%'}} />
                  }
                  {language === 'sk' && props.typVraceni === 'reklamace' &&
                    <img alt='' src="https://vratky.bola.cz/claim_sk.jpg" style={{maxWidth: '400px', width: '100%'}} />
                  }
                  <br />
                </div>


                  <div className="card " role="alert">

                  <ComponentToPrint ref={componentRef} stitekInfo={translate_label(props.stitekInfo, language)} />
                  <ReactToPrint
                    trigger={() => <button className="btn btn-primary">{t('Vytisknout štítek na balík')}</button>}
                    content={() => componentRef.current}
                  />
                  </div>

              </div>
            </div>

           


            </div>
          </div>


        </div>
      </div>

    )
}